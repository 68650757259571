import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import styles from "../../scss/common/ProductCard.module.scss";
import thanku from "../../assets/images/thanku.svg";
import { downloadPDF } from "../../common/utils/Functions";
import AppButton from "../../common/theme/Button";
const ThankYouCart = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const [orderId, setOrderId] = useState("");
  const [email, setEmail] = useState("");
  const [receipt, setReceipt] = useState("");
  const [storeName, setStoreName] = useState("");

  useEffect(() => {
    if (location?.state) {
      setOrderId(location?.state?.orderResponce?.orderId);
      setEmail(location?.state?.orderResponce?.email);
      setReceipt(location?.state?.orderResponce?.receipt);
      setStoreName(
        `${location?.state?.orderResponce?.storeName ? location?.state?.orderResponce?.storeName?.replaceAll(" ", "-") : `Order-Detail`}-${
          location?.state?.orderResponce?.orderId
        }.pdf`
      );
    }
  }, [location]);

  return (
    <>
      <div
        style={{ textAlign: "center", marginTop: "50px", marginBottom: "50px" }}
      >
        <img
          loading="lazy"
          src={thanku}
          className={styles.emptyShoppingImg}
          alt=""
        />
        <div className={styles.emptyShopping}>
          <h2 style={{ marginTop: "25px" }}>Thank You</h2>
          <p style={{ margin: "15px auto" }}>
            Your order <strong>#{orderId}</strong> has been placed.
          </p>

          <p style={{ marginBottom: "35px" }}>
            A copy of your receipt has been sent to <strong>{email}</strong>
          </p>
          <div style={{ marginTop: "10px" }}>
            <AppButton
              onClick={() => {
                navigate("/");
              }}
              style={{ marginRight: "10px" }}
            >
              Return to Store
            </AppButton>
            <AppButton
              onClick={() => downloadPDF(receipt, storeName)}
              variant="outlined"
            >
              Print Receipt
            </AppButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYouCart;
