import axios from "axios";
import saveAs from "file-saver";

export const strip_html_tags = (str: string) => {
  if (str === null || str === "") return "";
  else str = str.toString();
  return str.replace(/<[^>]*>/g, "");
};

export const clearUserLocalData = () => {
  localStorage.removeItem("RENath_userInfo");
  localStorage.removeItem("RENath_token");
  sessionStorage.removeItem("rm_accountTab");
};
export const imageOnError = (event: any) => {
  event.currentTarget.onerror = null; // prevents looping
  event.currentTarget.src = "/images/prodPlaceholder.png";
};

/** download pdf functions */
export const downloadPDF = (pdfUrl: string, customFileName: string) => {
  fetch(pdfUrl)
    .then((response: any) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.blob();
    })
    .then((blob) => {
      saveAs(blob, customFileName);
    })
    .catch((error) => {
      console.error("Error downloading the PDF file:", error);
    });
};

export const removeInitialSpace = (value: string) => value.replace(/^\s+/g, "");

// Get two numbers after decimals
export const trimNumber = (number: number) =>
  number?.toLocaleString("en-US", {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }) ?? "0.00";
