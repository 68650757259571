// ========= API BASE URL ==========
export const API_BASE_URL = process.env.REACT_APP_BASE_URL ?? "";
export const IMAGE_BASE_URL =
  process.env.REACT_APP_IMAGE_BASE_URL ??
  "https://ren-ath-dev.s3.us-east-2.amazonaws.com/";
const GET_URL =
  process.env.REACT_APP_MODE === "production"
    ? window.location.href
    : "https://marylandjuniorsgirlsstore2024.therenhub.com/";

// const GET_URL = window.location.href;

export const DIRECT_ACCESS_URL = GET_URL.split("//")[1].split(".")[0];

// =https://ren-ath-dev.s3.us-east-2.amazonaws.com/
// ========= COMMAN API METHODS ==========
export const POST = "POST";
export const GET = "GET";
export const PUT = "PUT";
export const DELETE = "DELETE";
