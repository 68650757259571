import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RotatingLines } from "react-loader-spinner";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteIcon } from "../../assets/Icons/icons";
import { imageOnError } from "../../common/utils/Functions";
import { IMAGE_BASE_URL } from "../../constants/constants";
import useFetch from "../../customHook/useFetch";
import styles from "../../scss/common/common.module.scss";
import BundleItemModal from "../../common/sections/bundleItemModal";
import ModalLayout from "../../common/layouts/ModleLayout";
import AppButton from "../../common/theme/Button";
const ProductCartShoppingCard = ({
  cartItem: cartProductItem,
  reLoadCart,
  setReLoadCart,
  setIsContinueDisable,
  itemIndex,
}: {
  cartItem: any;
  reLoadCart: boolean;
  setReLoadCart: (value: boolean) => void;
  setIsContinueDisable: any;
  itemIndex: number;
}) => {
  // const navigate = useNavigate();
  const storeData = useSelector((state: any) => state.storeDataSlice.data);
  const [open, setOpen] = React.useState(false);
  const [cartItem, setCartItem] = useState<any>();

  useEffect(() => {
    setCartItem(cartProductItem);
  }, [cartProductItem]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setReLoadCart(!reLoadCart);
    setOpen(false);
  };

  const [loadSingleProductsQuery, { loading: singleProductLoading }] = useFetch(
    `/get-products-info/public/${cartItem?.id}`,
    {
      method: "get",
    }
  );
  const [loadBundleQuery, { loading: bundleProductLoading }] = useFetch(
    `/bundle/info/${cartItem?.id}`,
    {
      method: "get",
    }
  );

  const callSingleProduct = async () => {
    const response = await loadSingleProductsQuery();
    if (response?.data?.status) {
      setCartItem({
        ...cartItem,
        minimum_quantity: response?.data?.data?.minimum_quantity,
        maximum_quantity: response?.data?.data?.maximum_quantity,
      });
    }
  };
  const callBundleProduct = async () => {
    const response = await loadBundleQuery();
    if (response?.data?.status) {
      setCartItem({
        ...cartItem,
        bundle_products: cartItem?.bundle_products?.map((item: any) => ({
          ...item,
          store_product_detail: {
            ...item?.store_product_detail,
            minimum_quantity: response?.data?.data?.bundle_products?.filter(
              (filterItem: any) => filterItem?.id === item?.id
            )?.[0]?.store_product_detail?.minimum_quantity,
            maximum_quantity: response?.data?.data?.bundle_products?.filter(
              (filterItem: any) => filterItem?.id === item?.id
            )?.[0]?.store_product_detail?.maximum_quantity,
          },
        })),
      });
    }
  };

  const [num, setNum] = useState<any>();
  const [qtyError, setQtyError] = useState("");
  useEffect(() => {
    if (qtyError === "") {
      setIsContinueDisable(false);
    } else {
      setIsContinueDisable(true);
    }
  }, [qtyError]);

  const [productCartQty, setProductCartQty] = useState(0);

  let handleChange = (e: any) => {
    if (e.target.value === "") {
      setNum("");
      setQtyError(
        "Minimum Quantity allowed is " + (cartItem?.minimum_quantity || "1")
      );
    } else if (e.target.value < 1) {
      setQtyError(
        "Minimum Quantity allowed is " + (cartItem?.minimum_quantity || "1")
      );
    } else {
      let minUpdate = false;
      let maxUpdate = false;
      if (cartItem?.isSingleProduct) {
        if (cartItem?.minimum_quantity && cartItem?.minimum_quantity !== null) {
          if (e.target.value >= cartItem?.minimum_quantity) {
            minUpdate = true;
          } else {
            minUpdate = false;
            setQtyError(
              "Minimum Quantity allowed is " +
                (cartItem?.minimum_quantity || "1")
            );
          }
        } else {
          if (e.target.value > 0) {
            minUpdate = true;
          } else {
            minUpdate = false;
            setQtyError("Minimum Quantity allowed is 1");
          }
        }
        if (cartItem?.maximum_quantity && cartItem?.maximum_quantity !== null) {
          if (
            Number(e.target.value) + Number(productCartQty) <=
            cartItem?.maximum_quantity
          ) {
            maxUpdate = true;
          } else {
            maxUpdate = false;
            setQtyError(
              "Maximum Quantity allowed is " +
                (cartItem?.maximum_quantity || "1")
            );
          }
        } else {
          if (e.target.value > 0) {
            maxUpdate = true;
          }
          // if()
        }
      }

      if (minUpdate && maxUpdate) {
        setQtyError("");

        let newCartData = [];
        const cartDataInfo = localStorage.getItem("cart");
        const cartOldData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

        if (cartOldData && cartOldData.length > 0) {
          newCartData = cartOldData;
          // const cartItemIndex = newCartData.findIndex(
          //   (cartProduct: any) => cartProduct.id === cartItem?.id
          // );
          newCartData[itemIndex] = {
            ...cartItem,
            quantity: parseInt(e.target.value),
          };
        }
        // newCartData.push(newCartData)
        const cartData = JSON.stringify(newCartData);

        localStorage.setItem("cart", cartData);
        setCartItem({
          ...cartItem,
          quantity: parseInt(e.target.value),
        });
      }
      if (parseInt(e.target.value) <= 1000) {
        setNum(parseInt(e.target.value));
      }
    }
    setReLoadCart(!reLoadCart);
  };

  const [cartImages, setCartImages] = useState([]);
  const [totalProductPrice, setTotalProductPrice] = useState(0);
  const [totalBundleProducts, setTotalBundleProducts] = useState(0);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [cartData, setCartData] = useState(false);
  const openDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const hasMultiImages = cartImages.length > 1;

  const removeItem = () => {
    setDeleteLoading(true);
    // let newCartData = [];
    const cartDataInfo = localStorage.getItem("cart");
    const cartOldData = cartDataInfo ? JSON.parse(cartDataInfo) : [];

    if (cartOldData && cartOldData.length > 0) {
      // newCartData = cartOldData;
      // const cartItemIndex = newCartData.findIndex(
      //   (cartProduct: any) => cartProduct.id === cartItem?.id
      // );
      cartOldData.splice(itemIndex, 1);
    }
    const cartData: any = JSON.stringify(cartOldData);
    setCartData(true);
    // setCartImages(cartData?.store_product_images[0]?.image);
    localStorage.setItem("cart", cartData);
    setReLoadCart(!reLoadCart);
    setDeleteLoading(false);
    setDeleteModal(false);
    toast.success("Removed successfully");
  };

  useEffect(() => {
    const images: any = [];

    if (cartItem) {
      if (!cartItem?.isSingleProduct) {
        cartItem?.bundle_products.map((product: any) => {
          if (product.store_product_detail.store_product_images.length > 0) {
            images.push({
              img: product.store_product_detail.store_product_images[0].image,
            });
          } else {
            images.push({ img: "/images/default-product.png", noImg: true });
          }
        });
      } else {
        if (cartItem?.store_product_images.length > 0) {
          images.push({ img: cartItem?.store_product_images[0].image });
        } else {
          images.push({ img: "/images/default-product.png", noImg: true });
        }
      }
    }
    setCartImages(images);
    setCartData(false);
    setNum(cartItem?.isSingleProduct ? cartItem?.quantity : 1);
  }, [cartData, cartItem]);

  useEffect(() => {
    if (cartItem) {
      let totalPrice = 0;
      let totalBundleProduct = 0;
      const cartDataInfo = localStorage.getItem("cart");
      const cartAllData = cartDataInfo ? JSON.parse(cartDataInfo) : [];
      if (!cartItem?.isSingleProduct) {
        cartItem?.bundle_products &&
          cartItem?.bundle_products.map((product: any) => {
            totalPrice +=
              product.store_product_detail.club_price *
              product.store_product_detail.quantity;
            totalBundleProduct += product.store_product_detail.quantity;
          });

        const cartItems = cartAllData.filter(
          (item: any) => item.id === cartItem?.id && item.isSingleProduct
        );

        let totalQty = 0;
        cartItems.map((item: any) => {
          if (item.cartItemId !== cartItem?.cartItemId) {
            totalQty += Number(item.quantity);
          }
        });

        setProductCartQty(totalQty);
      } else {
        const quantity =
          cartItem?.quantity !== undefined ? cartItem?.quantity : 1;
        totalPrice += cartItem?.club_price * quantity;
      }
      setTotalProductPrice(
        storeData?.payment_handle_type === 1 ? totalPrice : 0
      );
      setTotalBundleProducts(totalBundleProduct);
    }
  }, [cartItem, reLoadCart]);

  return (
    <div className={styles.shoppingCart_main}>
      <Box style={{ width: "100%", display: "flex" }}>
        <div className={styles.shoppingCart_img}>
          {cartImages.length ? (
            cartImages.map((img: any, index: number) =>
              index < 4 ? (
                <div
                  key={index}
                  className={`${styles.img_wrap} ${
                    hasMultiImages ? styles.multiImage : ""
                  }`}
                >
                  {index === 3 && cartImages.length > 4 ? (
                    <div className={styles.layer}>+{cartImages.length - 4}</div>
                  ) : (
                    ""
                  )}

                  <img
                    loading="lazy"
                    onError={(event) => imageOnError(event)}
                    src={img.noImg ? img.img : IMAGE_BASE_URL + img.img}
                    alt=""
                  />
                </div>
              ) : (
                ""
              )
            )
          ) : (
            <>
              <div className={styles.img_wrap}>
                <img
                  loading="lazy"
                  onError={(event) => imageOnError(event)}
                  src="/images/default-product.png"
                  alt=""
                />
              </div>
            </>
          )}
        </div>

        <div className={styles.shoppingChat_heading}>
          {!cartItem?.isSingleProduct ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span>{cartItem?.bundle_name}</span>
                <p
                  className={styles.viewBundle}
                  // onClick={() => navigate(`/bundle-detail/${cartItem?.id}`)}
                  onClick={() => {
                    callBundleProduct();
                    handleOpen();
                  }}
                >
                  <span>View Bundle</span>
                </p>
              </div>
              <div>
                <span>
                  {`$${parseFloat(totalProductPrice.toString()).toFixed(2)}`}
                </span>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                <span>{cartItem?.product_name}</span>
                {/* <p
                  className={styles.viewBundle}
                  // onClick={() => navigate(`/bundle-detail/${cartItem?.id}`)}
                  onClick={() => {
                    callSingleProduct();
                    handleOpen();
                  }}
                >
                  <span>View Detail</span>
                </p> */}
                {cartItem?.isSingleProduct &&
                  cartItem?.store_options &&
                  cartItem?.store_options.length > 0 &&
                  cartItem?.store_options.map((option: any, index: number) => {
                    const item = option.store_option_items.filter(
                      (optionItem: any) => optionItem.id === option.selectedData
                    );

                    if (option.option_type === 1 || option.option_type === 2) {
                      return (
                        <p className={styles.productPTag} key={index}>
                          {option.display_name}:{" "}
                          {item.length > 0
                            ? item[0].option_name
                            : option.selectedData}{" "}
                        </p>
                      );
                    }
                  })}
              </div>
              <span>
                {cartItem &&
                  `${
                    cartItem?.club_price === null
                      ? `$${(0).toFixed(2)}`
                      : `$${(cartItem?.club_price * cartItem?.quantity).toFixed(
                          2
                        )}`
                  }`}
              </span>
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
            className={styles.shoppingCart_container}
          >
            <button onClick={openDeleteModal}>Remove</button>
            <div className={styles.shoppingChat_items}>
              {!cartItem?.isSingleProduct && (
                <button className={styles.qtyBtn}>
                  Qty : 1 bundle ({totalBundleProducts} items)
                </button>
              )}

              {cartItem?.isSingleProduct && (
                <>
                  <div
                    className={`${styles.custom_incrementDecrement} ${styles.incDecInputWrap}`}
                  >
                    <div className={`${styles.input_group} `}>
                      <div className={styles.incDecWrap}>
                        <button
                          disabled={num <= (cartItem?.minimum_quantity || 1)}
                          className={styles.btn}
                          type="button"
                          onClick={() =>
                            handleChange({ target: { value: num - 1 } })
                          }
                        >
                          -
                        </button>
                      </div>
                      <input
                        type="number"
                        className={styles.form_control}
                        value={num}
                        onChange={handleChange}
                      />
                      <div className={styles.incDecWrap}>
                        <button
                          disabled={num >= (cartItem?.maximum_quantity || 1000)}
                          className={styles.btn_outline}
                          type="button"
                          onClick={() =>
                            handleChange({ target: { value: num + 1 } })
                          }
                        >
                          +
                        </button>
                      </div>
                    </div>
                  </div>
                  <p className={styles.errorMsg}>{qtyError}</p>
                </>
              )}

              <BundleItemModal
                cart={cartItem}
                title=""
                subtitle=""
                size=""
                price=""
                handleOpen={handleOpen}
                handleClose={handleClose}
                open={open}
                productLoading={singleProductLoading || bundleProductLoading}
              />
            </div>

            <ModalLayout
              modalWidth={470}
              open={deleteModal}
              headingCenter
              handleClose={openDeleteModal}
              handleOpen={openDeleteModal}
              title="Remove Item"
              icon={deleteIcon}
              subtitle={
                <p>
                  Are you sure you want to remove{" "}
                  {!cartItem?.isSingleProduct
                    ? `${cartItem?.bundle_name} bundle`
                    : `${cartItem?.product_name}`}{" "}
                  from the cart?
                </p>
              }
            >
              <div
                style={{
                  marginTop: "2.5rem",
                  display: "flex",
                  justifyContent: " center",
                  gap: "1.25rem",
                }}
              >
                <AppButton onClick={openDeleteModal}>No</AppButton>
                <AppButton
                  type="submit"
                  disabled={deleteLoading}
                  onClick={removeItem}
                >
                  {deleteLoading && (
                    <span className="loadingBars">
                      <RotatingLines
                        strokeColor="currentColor"
                        strokeWidth="5"
                        animationDuration="0.75"
                        width="20"
                      />
                    </span>
                  )}
                  Yes, Remove
                </AppButton>
              </div>
            </ModalLayout>
          </div>
        </div>
      </Box>

      {/*  for showing buttons in mobile view outside the images and price */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "20px",
          width: "100%",
          alignItems: "center",
        }}
        className={styles.shoppingCart_mob_view}
      >
        <button onClick={openDeleteModal}>Remove</button>
        <div className={styles.shoppingChat_items}>
          {!cartItem?.isSingleProduct && (
            <button className={styles.qtyBtn}>
              Qty : 1 bundle ({totalBundleProducts} items)
            </button>
          )}

          {cartItem?.isSingleProduct && (
            <>
              <div
                className={`${styles.custom_incrementDecrement} ${styles.incDecInputWrap}`}
              >
                <div className={`${styles.input_group} `}>
                  <div className={styles.incDecWrap}>
                    <button
                      disabled={num <= (cartItem?.minimum_quantity || 1)}
                      className={styles.btn}
                      type="button"
                      onClick={() =>
                        handleChange({ target: { value: num - 1 } })
                      }
                    >
                      -
                    </button>
                  </div>
                  <input
                    type="number"
                    className={styles.form_control}
                    value={num}
                    onChange={handleChange}
                  />
                  <div className={styles.incDecWrap}>
                    <button
                      disabled={num >= (cartItem?.maximum_quantity || 1000)}
                      className={styles.btn_outline}
                      type="button"
                      onClick={() =>
                        handleChange({ target: { value: num + 1 } })
                      }
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <p className={styles.errorMsg}>{qtyError}</p>
            </>
          )}

          <BundleItemModal
            cart={cartItem}
            title=""
            subtitle=""
            size=""
            price=""
            handleOpen={handleOpen}
            handleClose={handleClose}
            open={open}
            productLoading={singleProductLoading || bundleProductLoading}
          />
        </div>

        <ModalLayout
          modalWidth={470}
          open={deleteModal}
          headingCenter
          handleClose={openDeleteModal}
          handleOpen={openDeleteModal}
          title="Remove Item"
          icon={deleteIcon}
          subtitle={
            <p>
              Are you sure you want to remove{" "}
              {!cartItem?.isSingleProduct
                ? `${cartItem?.bundle_name} bundle`
                : `${cartItem?.product_name}`}{" "}
              from the cart?
            </p>
          }
        >
          <div
            style={{
              marginTop: "2.5rem",
              display: "flex",
              justifyContent: " center",
              gap: "1.25rem",
            }}
          >
            <AppButton onClick={openDeleteModal}>No</AppButton>
            <AppButton
              type="submit"
              disabled={deleteLoading}
              onClick={removeItem}
            >
              {deleteLoading && (
                <span className="loadingBars">
                  <RotatingLines
                    strokeColor="currentColor"
                    strokeWidth="5"
                    animationDuration="0.75"
                    width="20"
                  />
                </span>
              )}
              Yes, Remove
            </AppButton>
          </div>
        </ModalLayout>
      </div>
    </div>
  );
};

export default ProductCartShoppingCard;
