import { useState } from "react";
import { IMAGE_BASE_URL } from "../../constants/constants";
import styles from "../../scss/common/ProductCard.module.scss";
import { imageOnError, trimNumber } from "../utils/Functions";

interface IProps {
  bundle_products: Array<any>;
}
const TravelPackageCards = ({ bundle_products }: IProps) => {
  const [showImg, setShowImage] = useState(false);

  let lengthNumber = bundle_products.length - 4;

  return (
    <div className={styles.product_row}>
      {bundle_products?.length > 0
        ? bundle_products?.map((product: any, index: number) => {
            if (index < 3 || showImg === true) {
              return (
                <div className={styles.product_lg} key={index}>
                  <div className={styles.product_card}>
                    <div className={styles.card_img}>
                      {product?.subBundleName ? (
                        <div className={styles.sub_bundle_badge}>
                          {product?.subBundleName}
                        </div>
                      ) : (
                        ""
                      )}
                      <img
                        loading="lazy"
                        onError={(event) => imageOnError(event)}
                        src={
                          product?.store_product_detail?.store_product_images
                            .length
                            ? ` ${IMAGE_BASE_URL}${product?.store_product_detail?.store_product_images[0].image}`
                            : "/images/default-product.png"
                        }
                        alt="img"
                      />
                    </div>
                    <div className={styles.card_content}>
                      {product?.store_product_detail?.product_name && (
                        <div className={styles.title}>
                          <h6>{product?.store_product_detail?.product_name}</h6>
                        </div>
                      )}

                      <div className={styles.price}>
                        <h5>
                          $
                          {trimNumber(
                            product?.store_product_detail?.club_price ?? 0
                          )}
                        </h5>
                      </div>

                      {/* {button && <div className={styles.button}>{button}</div>} */}
                    </div>
                  </div>
                </div>
              );
            } else if (index === 3) {
              return (
                <div className={styles.product_lg}>
                  <div className={styles.product_card}>
                    <div className={styles.card_img}>
                      {product?.subBundleName ? (
                        <div className={styles.sub_bundle_badge}>
                          {product?.subBundleName}
                        </div>
                      ) : (
                        ""
                      )}
                      <div
                        className={`${styles.card_img_overlay} ${
                          lengthNumber ? styles.showOverlay : ""
                        }`}
                        onClick={() => {
                          setShowImage(true);
                        }}
                      >
                        <img
                          loading="lazy"
                          onError={(event) => imageOnError(event)}
                          src={
                            product?.store_product_detail?.store_product_images
                              .length
                              ? ` ${IMAGE_BASE_URL}${product?.store_product_detail?.store_product_images[0].image}`
                              : "/images/default-product.png"
                          }
                          alt="img"
                        />
                        {lengthNumber ? <h4>+{lengthNumber}</h4> : null}
                      </div>
                    </div>
                    <div className={styles.card_content}>
                      {product?.store_product_detail?.product_name && (
                        <div className={styles.title}>
                          <h6>{product?.store_product_detail?.product_name}</h6>
                        </div>
                      )}

                      <div className={styles.price}>
                        <h5>
                          ${" "}
                          {trimNumber(
                            product?.store_product_detail?.club_price ?? 0
                          )}
                        </h5>
                      </div>

                      {/* {button && <div className={styles.button}>{button}</div>} */}
                    </div>
                  </div>
                </div>
              );
            }
          })
        : ""}
    </div>
  );
};

export default TravelPackageCards;
