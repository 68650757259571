import styles from "../../scss/common/ProductCard.module.scss";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RotatingLines } from "react-loader-spinner";
import AppButton from "../../common/theme/Button";
import React from "react";
import AppContainer from "../../common/theme/Container";
import TravelPackageCards from "../../common/sections/TravelPackageCards";
import ShopOptionalSection from "./ShopOptionalSection";
const _ = require("lodash");
interface storeBundleDataType {
  storeBundleData: Array<any>;
  isBundles?: boolean;
  loading?: boolean;
}
const TravelPremierPackage = ({
  storeBundleData,
  isBundles,
  loading,
}: storeBundleDataType) => {
  const navigate = useNavigate();
  const storeDesignData = useSelector((state: any) => state.storeDesignList);
  console.log(storeBundleData, "storeBundleData");

  return (
    <>
      {loading ? (
        <span className="loadingScreen">
          <RotatingLines
            strokeColor="currentColor"
            strokeWidth="5"
            animationDuration="0.75"
            width="20"
          />
        </span>
      ) : storeBundleData?.length > 0 ? (
        <section className={styles.travel_package}>
          {storeBundleData?.map((item: any, index: number) => {
            let total = 0;
            item?.bundle_products?.map((product: any) => {
              if (product?.store_product_detail?.minimum_quantity !== null) {
                const mTotal =
                  product?.store_product_detail?.minimum_quantity *
                  product?.store_product_detail?.club_price;
                total += mTotal ?? 0;
              } else {
                total += product?.store_product_detail?.club_price ?? 0;
              }
            });
            const subBundleProducts: any[] = [];
            const subBundleArr = item?.sub_bundle?.map(
              (subBundle: any, index: number) => {
                let maxPrice = 0;
                subBundle?.bundle_products?.map((product: any) => {
                  const { club_price, minimum_quantity } =
                    product.store_product_detail;
                  const subBundlePriceInner = Math.max(club_price);
                  if (subBundlePriceInner > maxPrice) {
                    if (minimum_quantity !== null) {
                      maxPrice = minimum_quantity * subBundlePriceInner;
                    } else {
                      maxPrice = subBundlePriceInner;
                    }
                  }
                  subBundleProducts.push({
                    ...product,
                    subBundleName: `Sub Bundle ${index + 1}`,
                  });
                });
                return {
                  id: subBundle.id,
                  maxPrice,
                };
              }
            );

            const subBundleMaxPrice = subBundleArr?.reduce(
              (sum: any, item: any) => sum + item.maxPrice,
              0
            );
            total = total + subBundleMaxPrice;
            const bundle_products = item?.bundle_products ?? [];
            return (
              <AppContainer key={index}>
                <div className="heading">
                  <h2>
                    {item?.bundle_name}
                    <span style={{ fontSize: "34px" }}>
                      {" "}
                      {`($${total.toFixed(2)})`}
                    </span>
                  </h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: item?.description ? item?.description : "",
                    }}
                  />
                </div>

                <TravelPackageCards
                  bundle_products={[...bundle_products, ...subBundleProducts]}
                />
                {/* {item?.sub_bundle?.map((subBundle: any) => (
                  <div className={styles.subBundle}>
                    <div className={styles.subBundleHeader}>
                      <h3>Sub Bundle</h3>
                    </div>

                    <TravelPackageCards
                      bundle_products={subBundle?.bundle_products}
                    />
                  </div>
                ))} */}

                <div className={styles.textCenter}>
                  <AppButton
                    onClick={() => navigate(`/bundle-detail/${item?.id}`)}
                  >
                    Build Bundle
                  </AppButton>
                </div>
                {index === storeBundleData?.length - 1 ? (
                  storeDesignData?.data?.is_vector_image_show !== 2 ? (
                    <ShopOptionalSection />
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </AppContainer>
            );
          })}
        </section>
      ) : (
        <>
          {isBundles && storeDesignData?.data?.is_vector_image_show !== 2 ? (
            <>
              {storeDesignData?.searchType && !storeBundleData?.length ? (
                <div style={{ textAlign: "center" }}>No result found</div>
              ) : (
                ""
              )}

              <ShopOptionalSection />
            </>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default TravelPremierPackage;
